import React from "react"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

export const PageTemplate = ({ title, content }) => (
    <div id="content">
         <SEO title={`${title} | Art Specialists`} />

        <div id="page-content">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    </div>      
)

PageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
}

const Page = ({ data }) => {
    const { wordpressPage: page } = data

    console.log(data)

    return (
        <Layout slug={page.slug}>
            <PageTemplate title={page.title} content={page.content} />
        </Layout>
    )
}

Page.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
    }
  }
`